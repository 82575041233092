import { FETCH_DOWNLOADS } from "../actions/type";

const initState = {
  empty: true
};

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_DOWNLOADS:
      return action.data;
    default:
      return state;
  }
}
