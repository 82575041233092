import React, { useState } from "react";

const GoogleMaps = props => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  if (open) {
    return (
      <div className="row" style={{ backgroundColor: "#fcfcfc" }}>
        <div className="col-md-middle container-middle-big">
          <h1
            className="large primary bold"
            style={{ paddingBottom: "30px", paddingTop: "45px" }}
          >
            Finden Sie mich mit <br />
            <span className="secondary">Google Maps</span>
          </h1>
          <iframe
            title="google map location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2533.8043182862557!2d8.726108715899487!3d50.57500208626327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bcf639269fff21%3A0x9316f76321e4fcdb!2sKerkrader%20Str.%2011%2C%2035394%20Gie%C3%9Fen%2C%20Deutschland!5e0!3m2!1sde!2sat!4v1575638233404!5m2!1sde!2sat"
            frameBorder="0"
            style={{ width: "100%", height: "50vh" }}
            allowFullScreen=""
          ></iframe>

          <hr
            style={{
              width: "100%",
              display: "block",
              height: "1px",
              backgroundColor: "#BFA482",
              marginTop: "30px"
            }}
          />
          <div className="row">
            <div className="col-md-8 nopl nopr">
              <p className="secondary">
                Nikolaus Flechtner Rechtsanwalt - Fachanwalt für Stuererrecht
              </p>
            </div>
            <div className="col-md-4">
              <p className="secondary">T +49 641 / 13 27 27 31 | F - 30</p>
            </div>
          </div>
        </div>
        <div className="col-md-2 bg-primary-dark"></div>
      </div>
    );
  } else {
    return (
      <div className="row" style={{backgroundColor: '#fcfcfc'}}>
        <div className="col-md-10 container-middle-big">
          <h1
            className="large primary bold"
            style={{ paddingTop: "45px", paddingBottom: "30px" }}
          >
            Datenschutzhinweise <br />
            <span className="secondary">Google Maps</span>
          </h1>
          <p style={{ paddingTop: "30px", maxWidth: "500px" }}>
            Durch die Nutzung dieser Funktion erklären Sie sich mit der
            Erfassung, Bearbeitung sowie Nutzung der automatisiert erhobenen
            Daten durch Google Inc, dere Vertretern sowie Dritter einverstanden.
          </p>
          <p style={{ paddingTop: "15px" }}>
            Die Nutzungsbediengunen von Google Maps finden Sie unter: <br />
            <a
              href="https://www.google.com"
              className="link"
              style={{ color: "#BFA482" }}
            >
              https://www.google.com/intl/de_de/help terms_maps/
            </a>{" "}
            <br />
            Die Datenschutzerklärung von Google Inc unter: <br />
            <a
              href="https://www.google.com"
              className="link"
              style={{ color: "#BFA482" }}
            >
              https://www.google.com/intl/de/policies/privacy/index.html
            </a>
          </p>
          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            <div className="row">
              <div className="col-md-4 nop">
                <div
                  style={{ height: "100%" }}
                  className="row flex-align-center"
                >
                  <input
                    style={{ cursor: "pointer" }}
                    onClick={() => setChecked(!checked)}
                    type="checkbox"
                    value={false}
                    name="zustimmung"
                    id="zustimmung"
                  />
                  <label
                    for="zustimmung"
                    style={{ marginLeft: "15px", cursor: "pointer" }}
                    className={
                      checked ? "middle secondary" : "middle lightgrey-c"
                    }
                  >
                    Einverstanden
                  </label>
                </div>
              </div>
              <div className="col-md-4 nop">
                <div className="row flex-align-center">
                  <button
                    onClick={() => setOpen(!open)}
                    disabled={!checked}
                    className="btn-small-u"
                    style={{ color: "white" }}
                  >
                    weiter mit <span className="bold">Google Maps</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr
            style={{
              width: "100%",
              display: "block",
              height: "1px",
              backgroundColor: "#BFA482",
              marginTop: "45px"
            }}
          />
          <div className="row">
            <div className="col-md-8 nopl nopr nopb">
              <p className="secondary">
                <span className="bold">Nikolaus Flechtner</span> Rechtsanwalt - Fachanwalt für
                Stuererrecht
              </p>
            </div>
            <div className="col-md-4 nopb">
              <p className="secondary"><span className="bold">T</span> +49 641 / 13 27 27 31 | <span className="bold">F</span> - 30</p>
            </div>
          </div>
        </div>
        <div className="col-md-2 bg-primary-dark"></div>
      </div>
    );
  }
};

export default GoogleMaps;
