import React, { Component } from "react";
import { connect } from "react-redux";
import { fetch } from "../redux/actions";
import { NavItem, NavItemScrollLink } from "./navigation";
import Logo from "../media/logo.png";
import { NavLink } from "react-router-dom";

import { throttle } from "../components/reusable/helper/functional/_throttle";

import { ROOT_URL, SINGLETONS, GETKEYS } from "../config";

class DesktopNav extends Component {
  constructor() {
    super();
    this.throttledScrollEvent = throttle(this.handleScrollEvent, 100);
  }

  state = {
    width: window.innerWidth,
    navitems: {
      active: 4,
      all: ["", "| Leistungen", "Anwalt", "Honorar", "Kontakt"],
      scrollTo: ["/", "/#leistungen", "/#anwalt", "/#honorar", "/#kontakt"]
    }
  };

  componentDidMount() {
    if (this.props.info.empty) {
      this.props.fetch(
        "fetch_info",
        `${ROOT_URL + SINGLETONS}settings?token=${GETKEYS}`
      );
    }
    window.addEventListener("scroll", this.throttledScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScrollEvent);
  }

  checkElementPosition = elem => {
    var distance = 0;
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
    return (distance = distance < 0 ? 0 : distance);
  };

  handleScrollEvent = () => {
    let windowElementOffsetTop = window.pageYOffset;

    let Rechtsgebiet = document.getElementById("rechtsgebiete");
    let Anwalt = document.getElementById("anwalt");
    let Honorar = document.getElementById("honorar");
    let Kontakt = document.getElementById("kontakt");

    if (window.location.pathname !== "/") {
      this.setState({
        navitems: {
          active: 5,
          all: this.state.navitems.all,
          scrollTo: this.state.navitems.scrollTo
        }
      });
      return;
    }

    if (windowElementOffsetTop > this.checkElementPosition(Kontakt) - 300) {
      this.setState({
        navitems: {
          active: 4,
          all: this.state.navitems.all,
          scrollTo: this.state.navitems.scrollTo
        }
      });

      return;
    }

    if (windowElementOffsetTop > this.checkElementPosition(Honorar) - 300) {
      this.setState({
        navitems: {
          active: 3,
          all: this.state.navitems.all,
          scrollTo: this.state.navitems.scrollTo
        }
      });

      return;
    }

    if (windowElementOffsetTop > this.checkElementPosition(Anwalt) - 300) {
      this.setState({
        navitems: {
          active: 2,
          all: this.state.navitems.all,
          scrollTo: this.state.navitems.scrollTo
        }
      });

      return;
    }

    if (
      windowElementOffsetTop >
      this.checkElementPosition(Rechtsgebiet) - 300
    ) {
      this.setState({
        navitems: {
          active: 1,
          all: this.state.navitems.all,
          scrollTo: this.state.navitems.scrollTo
        }
      });

      return;
    }

    if (windowElementOffsetTop > 0) {
      this.setState({
        navitems: {
          active: 0,
          all: this.state.navitems.all,
          scrollTo: this.state.navitems.scrollTo
        }
      });

      return;
    }
  };

  renderScrollToNavLinks = () => {
    return this.state.navitems.all.map((data, i) => {
      if (i === this.state.navitems.active) {
        return (
          <NavItemScrollLink
            className={"avtiveNavlink fontSecondary"}
            to={this.state.navitems.scrollTo[i]}
            content={data}
          />
        );
      } else {
        return (
          <NavItemScrollLink
            className={"navlink fontSecondary"}
            to={this.state.navitems.scrollTo[i]}
            content={data}
          />
        );
      }
    });
  };
  renderScrollToDotLinks = () => {
    return this.state.navitems.all.map((data, i) => {
      if (i === this.state.navitems.active) {
        return (
          <a href={this.state.navitems.scrollTo[i]}>
            <div className="dot-active"></div>
          </a>
        );
      } else {
        return (
          <a href={this.state.navitems.scrollTo[i]}>
            <div className="dot"></div>
          </a>
        );
      }
    });
  };

  render() {
    return (
      <>
        <nav
          style={{
            top: this.props.top,
            backgroundColor: this.props.backgroundColor
          }}
        >
          <div className="row">
            <div className="container-big nop flexbox">
              <div className="col-md-2"></div>

              <div className="row fw">
                <div className="nop col-xl-9 col-ml-11">
                  <div className="row nop justify-end fw">
                    <div className="col-md-12">
                      <p
                        className="primary toptext align-right fontSecondary"
                        style={{ fontSize: "10pt" }}
                      >
                        T {this.props.info.tel} | Telefonzeiten:{" "}
                        {this.props.info.openTimesDay +
                          " " +
                          this.props.info.openTimesTime}
                        {this.props.info.tel_time}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-big flexbox no-wrap">
            <div className="col-md-2 nop row">
              <div
                style={
                  this.props.top === "-70px"
                    ? {
                        marginTop: "0",
                        height: "100%",
                        width: "1px",
                        backgroundColor: "#1a5f38",
                        transition: "200ms"
                      }
                    : {
                        marginTop: "-20px",
                        height: "100%",
                        width: "1px",
                        backgroundColor: "#1a5f38",
                        transition: "200ms"
                      }
                }
              ></div>
              <NavLink to="/">
              <div className="logoLink" onClick={() => window.scroll({top: 0})}>
              <img alt="Rechtsanwalt Logo" className="logo" src={Logo} />
              </div>
              </NavLink>
            </div>
            <div className="row fw">
              <div className="nop col-xl-9 col-ml-11">
                <div className="row nop justify-end fw">
                <NavItem
                  className="navlink"
                  to="/aktuelles"
                  content="Aktuelles"
                />
                  {this.renderScrollToNavLinks()}
                  <NavItem
                    className="navlink"
                    to="/downloads"
                    content="Download |"
                  />

                  <NavItem
                    className="navlink"
                    to="/impressum"
                    content=" Impressum"
                  />
                  <NavItem
                    className="navlink"
                    to="/datenschutz"
                    content="Datenschutz"
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
        {window.location.pathname !== "/" ? null : (
          <div className="sideScrollBar">{this.renderScrollToDotLinks()}</div>
        )}
      </>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.info,
    info: data.info
  };
};

export default connect(mapStateToProps, { fetch })(DesktopNav);
