import React from "react";
import { useSelector } from "react-redux";
import Modalbox from "../../../reusable/modalbox";
import GoogleMaps from "../../../reusable/googleMaps";
import ContactForm from "../../../reusable/contactForm";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { ROOT_URL, SINGLETONS, GETKEYS } from "../../../../config";

const Kontakt = () => {
  const infoData = useSelector(state => state.info);

  return (
    <div id="kontakt" className="row">
      <div className="col-md-2"></div>
      <div className="col-md-10 bg-primary-dark nop">
        <div className="container nom">
          <div className="col-s-12">
            <div className="row ">
              <div className="col-md-12 honorartext">
                <Fade left>
                  <h1 className="large bold white">Kontakt</h1>
                </Fade>
                <Fade bottom>
                  <p className="white pt-half">
                    Zuverlässige Beratung zur Klärung einer undurchsichtigen
                    Rechtslage
                  </p>
                </Fade>
              </div>
              <div className="col-md-4 honorartext sp-h6">
                <p
                  className="bold white pt-half"
                  style={{ paddingBottom: "15px" }}
                >
                  Nikolaus Flechtner Rechtsanwalt Fachanwalt für Steuerrecht
                </p>
                <p className="white">T {infoData.tel}</p>
                <p className="white">F {infoData.fax}</p>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Modalbox
                    btnStyle="btn-white-u"
                    title="JETZT kontaktieren"
                    content={<ContactForm />}
                  />
                </div>
              </div>
              <div className="col-md-4 honorartext sp-h6">
                <p
                  className="small bold white pt-half"
                  style={{ paddingBottom: "15px" }}
                >
                  Adresse
                </p>
                <p className="white">
                  {infoData.street} <br /> {infoData.place} <br />{" "}
                  {infoData.land}
                </p>
                <hr
                  className="smallhr-fullAndSmall"
                  style={{ borderColor: "white", marginTop: "15px" }}
                />
                <p
                  className="small bold white"
                  style={{ paddingBottom: "15px", paddingTop: "15px" }}
                >
                  Wegbeschreibung
                </p>
                <p className="white">
                  Die Kanzlei befindet sich im Technologie- und
                  Innovationszentrum Gießen im Europaviertel.
                </p>
                <p
                  className="small bold white"
                  style={{ paddingBottom: "15px", paddingTop: "15px" }}
                >
                  Öffentliche Verkehrsmittel
                </p>
                <p className="white">
                  Mit dem Bus fahren Sie mit der Linie 22 - Gießen - Fernwald -
                  bis zur Haltestelle „Europastraße“.
                </p>
                <p
                  className="small bold white"
                  style={{ paddingBottom: "15px", paddingTop: "15px" }}
                >
                  Finden Sie mich mit
                </p>
                <div style={{ marginBottom: "15px" }}>
                  <Modalbox
                    btnStyle="btn-beige-u"
                    title="Google Maps"
                    content={<GoogleMaps />}
                  />
                  <hr
                    className="smallhr-fullAndSmall"
                    style={{ borderColor: "white", marginTop: "15px" }}
                  />
                </div>
              </div>
              <div className="col-md-4 honorartext">
                <div
                  className="row justify-center flex-align-center"
                  style={{ height: "100%" }}
                >
                  <div className="honorartext sp-h6">
                    <p
                      className="small bold white"
                      style={{ paddingBottom: "15px" }}
                    >
                      Telefonzeiten
                    </p>
                    <p className="white">
                      {infoData.openTimesDay} <br />
                      {infoData.openTimesTime}
                    </p>
                    <p
                      className="small bold white"
                      style={{ paddingBottom: "15px", paddingTop: "15px" }}
                    >
                      Terminvereinbarung
                    </p>
                    <p className="white">
                      Termine - nur nach Vereinbarung Sondertermine gerne auch
                      bei Ihnen und außerhalb üblicher Bürozeiten. Bitte
                      sprechen Sie mich an.
                    </p>
                    <hr
                      className="smallhr-fullAndSmall"
                      style={{ borderColor: "white", marginTop: "15px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-full nop nom"
          style={{ borderTop: "1px solid #BFA482" }}
        >
          <div className="row">
            <div className="col-s-12">
              <div className="container nom row justify-between">
                <div className="honorartext nopt nopb">
                  <p className="secondary" style={{ fontSize: "9pt" }}>
                    &copy; Nikolaus Flechtner Rechtsanwalt - Fachanwalt für
                    Steuerrecht
                  </p>
                </div>

                <div className="honorartext nop">
                  <p className="secondary" style={{ fontSize: "9pt" }}>
                    |
                  </p>
                </div>

                <div className="honorartext nopt nopb">
                  <p className="secondary" style={{ fontSize: "9pt" }}>
                    Design & Programmierung by <a href="https://www.designintime.at" target="_blank" className="secondary2" style={{ fontSize: "9pt" }}>designintime werbeagentur e.U.</a>
                  </p>
                </div>
                <div className="honorartext nopt nopb">
                  <p className="secondary" style={{ fontSize: "9pt" }}>
                    <NavLink
                      className="secondary"
                      to="/impressum"
                      style={{ fontSize: "9pt" }}
                    >
                      Impressum
                    </NavLink>{" "}
                    |{" "}
                    <NavLink
                      className="secondary"
                      to="/datenschutz"
                      style={{ fontSize: "9pt" }}
                    >
                      Datenschutz
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
