import React from 'react';
import './App.css';

import Main from './router';
import Navigation from './router/navigation';

function App() {
  return (
    <>
      <Navigation />
      <Main />
    </>
  );
}

export default App;
