import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import CookieNotification from '../components/reusable/cookieNotification';

import Home from '../components/pages/home';
import Aktuelles from '../components/pages/aktuelles';
import Leistungen from '../components/pages/leistungen';
import Datenschutz from '../components/pages/datenschutz';
import Downloads from '../components/pages/download';
import Impressum from '../components/pages/impressum';
import Footer from './footer';


export default class Main extends Component {


  render() {
    return (
      <>
      <CookieNotification />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/aktuelles" component={Aktuelles} />
          <Route exact path="/leistungen/:id" component={Leistungen} />
          <Route exact path="/downloads" component={Downloads} />
          <Route exact path="/datenschutz" component={Datenschutz} />
          <Route exact path="/impressum" component={Impressum} />
        </Switch>
      </>
    );
  }
};
