import React from "react";
import { NavLink } from "react-router-dom";

const Footer = props => {
  return (
    <div
      className="container-full nop nom bg-primary-dark"
      style={{ borderTop: "1px solid #BFA482" }}
    >
      <div className="row">
        <div className="col-s-12">
          <div className="container row justify-space-between">
            <div className="honorartext nop">
              <p className="secondary" style={{ fontSize: "9pt" }}>
                &copy; Nikolaus Flechtner Rechtsanwalt - Fachanwalt für
                Steuerrecht
              </p>
            </div>

            <div className="honorartext nop">
              <p className="secondary" style={{ fontSize: "9pt" }}>
                |
              </p>
            </div>

            <div className="honorartext nopt nop">
              <p className="secondary" style={{ fontSize: "9pt" }}>
                Design & Programmierung by <a className="secondary" style={{ fontSize: "9pt" }} href="https://designintime.at">designintime werbeagentur e.U.</a>
              </p>
            </div>
            <div className="honorartext nopt nop">
              <p className="secondary" style={{ fontSize: "9pt" }}>
                <NavLink
                  className="secondary"
                  to="/impressum"
                  style={{ fontSize: "9pt" }}
                >
                  Impressum
                </NavLink>{" "}
                |{" "}
                <NavLink
                  className="secondary"
                  to="/datenschutz"
                  style={{ fontSize: "9pt" }}
                >
                  Datenschutz
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
