import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetch } from "../../../redux/actions/index";
import { ROOT_URL, SINGLETONS, GETKEYS } from "../../../config";
import { Helmet } from "react-helmet";
import Footer from "../../../router/footer";

const Aktuelles = props => {
  const aktuellesData = useSelector(state => state.aktuelles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (aktuellesData.empty) {
      dispatch(
        fetch(
          "fetch_aktuelles",
          `${ROOT_URL + SINGLETONS}aktuelles?token=${GETKEYS}`
        )
      );
    }
  });

  return (
    <>
      <div
        className="container-big"
        style={{
          borderRight: "1px solid #1a5f38",
          borderLeft: "1px solid #1a5f38"
        }}
      >
        <Helmet>
          <title>
            präzise - persönlich - flexibel | Rechtsanwalt Flechtner |
            Impressum{" "}
          </title>
          <link rel="canonical" href={window.location.origin + "/impressum"} />
        </Helmet>
        <div className="container mainContainer">
          <h1 className="primary large break-word-medium">Aktuelles</h1>
        </div>
        <div
          className="container datenschutz list"
          dangerouslySetInnerHTML={{ __html: aktuellesData.text }}
        ></div>
      </div>
      <Footer />
    </>
  );
};

export default Aktuelles;
