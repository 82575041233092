import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetch } from "../../../redux/actions/index";
import { ROOT_URL, COLLECTIONS, GETKEY } from "../../../config";

import Download from "../../../media/download-button.png";

import { Helmet } from "react-helmet";

import Footer from "../../../router/footer";

const Downloads = props => {
  const downloadData = useSelector(state => state.download);
  const dispatch = useDispatch();

  useEffect(() => {
    if (downloadData.empty) {
      dispatch(
        fetch(
          "fetch_downloads",
          `${ROOT_URL + COLLECTIONS}downloads?token=${GETKEY}`
        )
      );
    }
  });

  const renderDownloads = () => {
    if (!downloadData.empty) {
      return downloadData.entries.map(data => {
        return (
          <>
            <div className="col-md-6 nopl nopr">
              <div className="row">
                <div className="col-md-4 shadow-download">
                  <div
                    className="flexbox justify-center flex-align-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${ROOT_URL + "/storage/uploads" + data.data.path}`}
                    >
                      <div
                        className="flexbox justify-center flex-align-center"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        <img
                          style={{ width: "50%" }}
                          alt="test"
                          src={Download}
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-md-8 flexbox flex-align-center">
                  <h3 className="primary small">
                    {data.title}
                  </h3>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>
          präzise - persönlich - flexibel | Rechtsanwalt Flechtner | Download{" "}
        </title>
        <link rel="canonical" href={window.location.origin + "/downloads"} />
      </Helmet>
      <div className="row">
        <div className="container">
          <div className="row" style={{ marginTop: "200px" }}>
            <div className="col-md-12">
              <h1 className="large bold primary">Download</h1>
              <p style={{ marginTop: "50px" }} className="primary bold">
                Bitte laden Sie hier Ihre gewünschten Daten herunter!
              </p>
            </div>
            <div className="col-md-12">
              <div className="row">{renderDownloads()}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Downloads;
