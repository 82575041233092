import React, { Component } from "react";
import { connect } from "react-redux";
import AnwaltFoto from "../../../../media/anwalt.jpg";
import { ROOT_URL, SINGLETONS, GETKEYS } from "../../../../config";
import { fetch } from "../../../../redux/actions/";
import Fade from "react-reveal/Fade";

class Anwalt extends Component {
  componentDidMount() {
    if (this.props.loaded.empty) {
      this.props.fetch(
        "fetch_anwalt",
        `${ROOT_URL + SINGLETONS}homepage?token=${GETKEYS}`
      );
    }
  }

  renderHelper = () => {
    if (this.props.loaded.empty) {
      return (
        <div>
          <p>...loading</p>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-lg-5 col-s-12 col-ml-8">
            <Fade left>
              <img
                className="anwaltBild"
                src={
                  !this.props.anwalt.imageAnwalt.path
                    ? "no image"
                    : this.props.anwalt.imageAnwalt.path
                }
                alt="Rechtsanwalt Nikolaus Flechtner"
                style={
                  !this.props.anwalt.imageAnwalt.path
                    ? {
                        boxShadow: "0 0 20px rgba(0,0,0,0.40)",
                        marginTop: "30px",
                        height: "450px",
                        backgroundColor: "grey"
                      }
                    : {
                        boxShadow: "0 0 20px rgba(0,0,0,0.40)",
                        marginTop: "30px",
                      }
                }
              />
            </Fade>
          </div>
          <Fade delay={400}>
            <div
              className="col-lg-5 col-s-12 col-ml-12 anwaltBild datenschutz-woutBreak"
              dangerouslySetInnerHTML={{
                __html: this.props.anwalt.textAnwalt
              }}
            ></div>
          </Fade>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-2 col-ml-2 removeColOnSmallDevice"></div>
        <div
          className="col-lg-10 col-ml-10 anwalt align-left"
          style={{ backgroundColor: this.props.bgColor, transition: "600ms" }}
          id="anwalt"
        >
          <div className="row">
            <div className="col-lg-12">
              <Fade top>
                <h1 className="large pt-half anwaltPaddingLeft bold">
                  präzise - persönlich - flexibel
                </h1>
              </Fade>
            </div>
          </div>
          {this.renderHelper()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.anwalt,
    anwalt: data.anwalt
  };
};

export default connect(mapStateToProps, { fetch })(Anwalt);
