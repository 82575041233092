import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { NavItem, NavItemScrollLink } from "./navigation";
import Logo from "../media/logo.png";

class MobileNav extends Component {
  state = {
    width: window.innerWidth,
    mobileOpen: false
  };

  toggleNav = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    return (
      <>
        <div className="mobileNav">
          <div
            onClick={this.toggleNav}
            className={"flexbox justify-center flex-align-center navIcon " + this.state.mobileOpen}
          >
            <p style={{marginTop: '-2px'}}>+</p>
          </div>
        </div>
        <div
          className={
            "mobile-nav-container row justify-center flex-align-center " +
            this.state.mobileOpen
          }
        >
          <div
            onClick={this.toggleNav}
            className={
              "flexbox justify-center flex-align-center navIcon2 " +
              this.state.mobileOpen
            }
          >
            <p style={{ marginTop: "-2px" }}>x</p>
          </div>
          <div className="nav-item-container">
            <span onClick={this.toggleNav}>
              <NavItemScrollLink
                className={"navlink"}
                to={"/aktuelles"}
                content={"Aktuelles"}
              />
            </span>
            <span onClick={this.toggleNav}>
              <NavItemScrollLink
                className={"navlink"}
                to={"/#leistungen"}
                content={"Leistungen"}
              />
            </span>
            <span onClick={this.toggleNav}>
              <NavItemScrollLink
                className={"navlink"}
                to={"/#anwalt"}
                content={"Anwalt"}
              />
            </span>
            <span onClick={this.toggleNav}>
              <NavItemScrollLink
                className={"navlink"}
                to={"/#honorar"}
                content={"Honorar"}
              />
            </span>
            <span onClick={this.toggleNav}>
              <NavItemScrollLink
                className={"navlink"}
                to={"/#kontakt"}
                content={"Kontakt"}
              />
            </span>
            <span onClick={this.toggleNav}>
              <NavItem to="/downloads" content="Download" />
            </span>
            <span onClick={this.toggleNav}>
              <NavItem to="/impressum" content="Impressum" />
            </span>
            <span onClick={this.toggleNav}>
              <NavItem to="/datenschutz" content="Datenschutz" />
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default MobileNav;
