import { FETCH_AKTUELLES } from "../actions/type";

const initState = {
  empty: true
};

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_AKTUELLES:
      return action.data;
    default:
      return state;
  }
}
