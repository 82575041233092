import { FETCH_SERVICE } from "../actions/type";

const initState = {
    empty: true
};

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_SERVICE:
      return action.data.entries;
    default:
      return state;
  }
}
