import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import MobileNav from "./mobile-nav";
import DesktopNav from "./desktop-nav";
import { throttle } from "../components/reusable/helper/functional/_throttle";

class Navigation extends Component {
  constructor() {
    super();
    this.throttledScrollEvent = throttle(this.handleScrollEvent, 100);
  }

  state = {
    width: window.innerWidth,
    location: window.location.href,
    backgroundColor: "transparent",
    top: "0",
    window: window.pageYOffset
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    window.addEventListener("scroll", this.throttledScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.throttledScrollEvent);
  }

  resize = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  handleScrollEvent = () => {
    let windowElementOffsetTop = window.pageYOffset;

    if (windowElementOffsetTop > this.state.window) {
      this.setState({
        top: "-70px",
        backgroundColor: "#fcfcfc",
        window: windowElementOffsetTop
      });
    }
    if (windowElementOffsetTop < this.state.window) {
      this.setState({
        top: "0px",
        backgroundColor: "#fcfcfc",
        window: windowElementOffsetTop
      });
    }
    if (windowElementOffsetTop < 100) {
      this.setState({ top: "0px", backgroundColor: "rgba(255,255,255,0.1)" });
    }
    return;
  };

  render() {
    if (this.state.width >= 1024) {
      return (
        <>
          <DesktopNav
            top={this.state.top}
            width={this.state.width}
            backgroundColor={this.state.backgroundColor}
          />
        </>
      );
    } else {
      return (
        <>
          <MobileNav />
        </>
      );
    }
  }
}

export const NavItem = props => (
         <div className="nav-item-container flexbox flex-align-center">
           <p
             onClick={() => window.scroll({ top: 0 })}
             className="navlink"
           >
             <NavLink
               activeClassName="avtiveNavlink"
               className="fontSecondary"
               exact
               to={props.to}
             >
               {props.content}
             </NavLink>
           </p>
         </div>
       );

export const NavItemScrollLink = props => (
  <div className="nav-item-container flexbox flex-align-center">
    <p onClick={() => window.scroll({ top: 0 })} className="navlink">
      <a className={props.className} href={props.to}>
        {props.content}
      </a>
    </p>
  </div>
);

export default Navigation;
