import { FETCH_INFO} from '../actions/type';

const initState = {
  empty: true
};

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_INFO:
    return action.data;
    default:
      return state;
  }
}
