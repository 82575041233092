import React, { Component } from "react";
import Logo from "../../../../media/logo-mobile.png";
import { fetch } from "../../../../redux/actions";
import { connect } from "react-redux";
import { ROOT_URL, SINGLETONS, GETKEYS } from "../../../../config";
// import {ModalboxContainer} from './modalbox';
import Fade from "react-reveal/Fade";
import Modalbox from "../../../reusable/modalbox";
import ContactForm from "../../../reusable/contactForm";
import { detectIE } from "../../../reusable/helper/functional/_browserDetect";

class Header extends Component {
  componentDidMount() {
    if (!this.props.loaded.empty) {
      this.props.fetch(
        "fetch_info",
        `${ROOT_URL + SINGLETONS}settings?token=${GETKEYS}`
      );
    }
  }
  state = {
    modalActive: false
  };

  toggle = () => {
    this.setState({ modalActive: !this.state.modalActive });
  };

  render() {
    return (
      <div className="header">
        <div className="row flex-align-center header-container">
          <div
            className="balkenRightSite fullToSmall"
            style={{ backgroundColor: this.props.bgColor, transition: "600ms" }}
          ></div>

          <div className="fixBalkenSecondary">
            <div className="container-big" style={{ position: "relative" }}>
              <div className="topBarSecondary"></div>
            </div>
          </div>
          <div className="container row justify-center nop">
            <Fade top>
              <img
                style={{ width: "auto", maxWidth: '250px' }}
                src={Logo}
                className="header-logo removeOn1024"
                alt="logo nikolaus flechtner rechtsanwalt"
              />
            </Fade>
          </div>
          <Fade delay={400}>
            <div
              className="removeOn1024"
              style={{
                padding: "5% 25px 5% 25px",
                textAlign: "center",
                width: "100%"
              }}
            >
              <p className="fontSecondary small">
                <span className="bold">T</span> {this.props.info.tel}
              </p>
              <p className="fontSecondary small">
                Telefonzeiten: {this.props.info.openTimesDay}{" "}
                {this.props.info.openTimesTime}
              </p>
            </div>
          </Fade>

          <Fade delay={100}>
            <div
              className={
                detectIE() <= 11 && detectIE() !== false
                  ? "fixOnIE headerBalken"
                  : "headerBalken"
              }
            >
              <div className="row justify-center">
                <div className="col-s-12">
                  <div className="container headerBalkenLines">
                    <Fade right delay={100}>
                      <h1
                        className="headline primary-dark"
                        style={{ maxWidth: "1000px" }}
                      >
                        Es genügt nicht,<span className="primary"> Recht</span>{" "}
                        zu haben, man muss es auch{" "}
                        <span className="primary">bekommen</span>.
                      </h1>
                    </Fade>
                    <div className="container nop">
                      <Fade right delay={100}>
                        <p
                          className="large align-left fontSecondary"
                          style={{ fontSize: "calc(7pt + 0.6rem)" }}
                        >
                          - Deutscher Rechtsspruch
                        </p>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>

          <div className="flexbox fw spOn1024">
            <div className="container">
              <div className="flexbox fix-center-mobile">
                <Modalbox
                  btnStyle="btn"
                  title="jetzt kontaktieren"
                  content={<ContactForm />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.info,
    info: data.info
  };
};

export default connect(mapStateToProps, { fetch })(Header);
