export const GETKEY = "ecfe0ff766f874a397002de55df4ac";
export const GETKEYS = "b8bf2906fb9a62a59799ab775aacfe";
export const COLLECTIONS = "/api/collections/get/";
export const SINGLETONS = "/api/singletons/get/";

export const ROOT_URL = "https://rechtsanwalt-flechtner.de/cockpit-master";
export const ROOT_URL_ORIGIN = "https://rechtsanwalt-flechtner.de/cockpit-master";

// export const ROOT_URL = `${window.location.origin}/cockpit-master`;
// export const ROOT_URL_ORIGIN = `${window.location.origin}/cockpit-master`;
