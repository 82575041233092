import React, { useState } from "react";

const Modalbox = props => {
  const [open, setOpen] = useState(false);

    const toggleModal = () => {
        setOpen(!open);
    }

  return (
    <>
      <button onClick={() => toggleModal()} className={props.btnStyle}>
        {props.title}
      </button>
      {open === true ? <BoxDesign toggleModal={toggleModal} content={props.content} /> : null}
    </>
  );
};

const BoxDesign = props => {
  return (
    <div
      className="transitionEverything"
      style={{
        position: "fixed",
        zIndex: "10000000000",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,255,0.6)",
        overflowY: "scroll",
        overflowX: "hidden"
      }}
    >
      <div
        className={`row ${
          window.innerWidth <= 768 || window.innerHeight <= 850 ? null : " justify-center flex-align-center"
        }`}
        style={{ width: "100%", height: "100%", backgroundColor: "#fcfcfc" }}
      >
        <div
          className="container-big nop"
          style={{
            backgroundColor: "white",
            boxShadow: "0 0 20px rgba(0,0,0,0.05)"
          }}
        >
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "15px",
              top: "15px",
              zIndex: "5",
              transition: "200ms",
              backgroundColor: "#BFA482"
            }}
            onClick={props.toggleModal}
          >
            <div
              className="flexbox justify-center flex-align-center"
              style={{
                width: "30px",
                height: "30px"
              }}
            >
              <p style={{ color: "white", marginTop: "-2px" }}>x</p>
            </div>
          </div>
          {props.content}
        </div>
      </div>
    </div>
  );
};

export default Modalbox;
