import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { NavItemScrollLink } from "../../../router/navigation";
import { connect } from "react-redux";
import { fetch } from "../../../redux/actions";
import { Helmet } from 'react-helmet';

import Service from "./service";

import Left from "../../../media/Pfeile_web_links.png";
import Right from "../../../media/Pfeile_web_rechts.png";

import Footer from "../../../router/footer";

import { ROOT_URL, COLLECTIONS, GETKEY } from "../../../config";

class Leistungen extends Component {
  componentDidMount() {
    if (this.props.loaded.empty) {
      this.props.fetch(
        "fetch_service",
        `${ROOT_URL + COLLECTIONS}service?token=${GETKEY}`
      );
    }
  }

  nextService = () => {
    if (!this.props.service.empty) {
      return this.props.service.map((data, i) => {
        const next = this.props.service[i + 1];
        if (window.location.pathname === "/leistungen/" + data.slug) {
          if (next) {
            return (
              <NavLink to={"/leistungen/" + next.slug}>
                <div className="flexbox flex-align-center">
                  <p
                    style={{
                      color: "white",
                      textAlign: "right",
                      marginRight: "5px"
                    }}
                  >
                    {next.title}
                  </p>
                  <img
                    alt="next"
                    style={{ width: "45px", height: "13px" }}
                    src={Right}
                  />
                </div>
              </NavLink>
            );
          }
        }
      });
    }
  };
  prevService = () => {
    if (!this.props.service.empty) {
      return this.props.service.map((data, i) => {
        const prev = this.props.service[i - 1];
        if (window.location.pathname === "/leistungen/" + data.slug) {
          if (prev) {
            return (
              <NavLink to={"/leistungen/" + prev.slug}>
                <div className="flexbox flex-align-center">
                  <img
                    alt="next"
                    style={{ width: "45px", height: "13px" }}
                    src={Left}
                  />
                  <p
                    style={{
                      color: "white",
                      textAlign: "right",
                      marginLeft: "5px"
                    }}
                  >
                    {prev.title}
                  </p>
                </div>
              </NavLink>
            );
          }
        }
      });
    }
  };

  renderService = () => {
    if (!this.props.service.empty) {
      return this.props.service.map((data, i) => {
        if (window.location.pathname === "/leistungen/" + data.slug) {
          return <Service data={data} />;
        }
      });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>
            {" "}
            präzise - persönlich - flexibel | Rechtsanwalt Flechtner |
            Leistungen{" "}
          </title>
          <link
            rel="canonical"
            href={window.location.origin + window.location.pathname}
          />
        </Helmet>
        <div>
          {this.renderService()}
          <div className="container">
            <div className=" col-ms-12">
              <div
                className="flexbox flex-align-center"
                style={{ float: "left" }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "1px",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    margin: "none",
                    marginRight: "5px"
                  }}
                ></div>
                <p style={{float: 'left'}}>
                  <a href="/#leistungen" className=" fontSecondary primary">
                    zurück
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="bottomBar">
            <div className="container">
              <div className="row">
                <div className="col-md-6 flexbox justify-start">
                  {this.prevService()}
                </div>
                <div className="col-md-6 flexbox justify-end">
                  {this.nextService()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.service,
    service: data.service
  };
};

export default connect(mapStateToProps, { fetch })(Leistungen);
