import React, { Component, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetch } from "../../../redux/actions";
import { ROOT_URL, SINGLETONS, GETKEYS } from "../../../config";

import axios from "axios";

const ContactForm = props => {
  const dispatch = useDispatch();
  const infoData = useSelector(state => state.info);

  useEffect(() => {
    if (infoData.empty) {
      dispatch(
        fetch("fetch_info", `${ROOT_URL + SINGLETONS}settings?token=${GETKEYS}`)
      );
    }
  });

  return (
    <div className="row" style={{ backgroundColor: "#fcfcfc" }}>
      <div className="col-md-middle container-middle-big">
        <h1
          className="large primary bold"
          style={{
            paddingBottom: "15px",
            paddingTop: "45px",
            paddingLeft: "15px"
          }}
        >
          Kontaktformular
        </h1>
        <p
          className="primary"
          style={{
            paddingBottom: "15px",
            paddingLeft: "20px"
          }}
        >
          Haben Sie Fragen?
          <br />
          <span className="bold">Dann nehmen Sie Kontakt mit mir auf!</span>
        </p>
        <div className="row">
          <div className="col-md-8 nop">
            <ContactFormStyle />
          </div>
          <div className="col-md-4 nopl">
            <div
              style={{
                borderLeft: "1px solid rgba(0,0,0,0.1)",
                paddingLeft: "15px"
              }}
            >
              <p className="small bold" style={{ marginBottom: "15px" }}>
                {" "}
                Nikolaus Flechtner Rechtsanwalt Fachanwalt für Steuerrecht
              </p>
              <p>
                <span className="bold">T</span> {infoData.tel}
              </p>
              <p>
                <span className="bold">F</span> {infoData.fax}
              </p>
              <p>
                <span className="bold">E</span> {infoData.email}
              </p>
              <p
                className="small bold"
                style={{ marginTop: "15px", marginBottom: "15px" }}
              >
                {" "}
                Telefonzeiten
              </p>
              <p>{infoData.openTimesDay}</p>
              <p>{infoData.openTimesTime}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2 bg-primary-dark"></div>
    </div>
  );
};

export default ContactForm;

class ContactFormStyle extends Component {
  state = {
    fname: "",
    lname: "",
    email: "",
    subject: "",
    message: "",
    checked: true,
    error: false,
    mailSent: false,
    alertAsRequired: {
      fname: false,
      lname: false,
      email: false,
      subject: false,
      message: "Diese Feld ist erforderlich"
    }
  };

  checkFieldValue = field => {
    if (this.state[field] === "") {
      this.setState({
        alertAsRequired: { [field]: true, ...this.state.alertAsRequired },
        ...this.state
      });
    }
  };

  handleFormSubmit = event => {
    const { fname, lname, email, subject } = this.state;
    if (fname === "" || lname === "" || email === "" || subject === "") {
      this.checkFieldValue("fname");
      this.checkFieldValue("lname");
      this.checkFieldValue("email");
      this.checkFieldValue("subject");
      return;
    }

    const API_PATH = "http://rechtsanwalt-flechtner.de/send.php";
    event.preventDefault();

    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          fname: "",
          lname: "",
          email: "",
          subject: "",
          message: "",
          checked: true,
          error: false,
          alertAsRequired: {
            fname: false,
            lname: false,
            email: false,
            subject: false,
            message: "Diese Feld ist erforderlich"
          }
        });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
  };

  onChangeHandler = (sName, e) => {
    this.setState({
      [sName]: e.target.value
    });
  };

  render() {
    return (
      <form className="themed" action="#">
        <div className="row">
          <div className="col-md-6">
            <input
              placeholder="Vorname"
              required
              name="firstname"
              id="firstname"
              value={this.state.fname}
              onChange={e => this.onChangeHandler("fname", e)}
            />
          </div>
          <div className="col-md-6">
            <input
              placeholder="Nachname"
              required
              name="lastname"
              id="lastname"
              value={this.state.lname}
              onChange={e => this.onChangeHandler("lname", e)}
            />
          </div>
          <div className="col-md-12">
            <input
              placeholder="E-Mail"
              required
              name="email"
              id="email"
              value={this.state.email}
              onChange={e => this.onChangeHandler("email", e)}
            />
          </div>
          <div className="col-md-12">
            <input
              placeholder="Betreff"
              required
              name="subject"
              id="subject"
              value={this.state.subject}
              onChange={e => this.onChangeHandler("subject", e)}
            />
          </div>
          <div className="col-md-12">
            <textarea
              style={{ height: "200px" }}
              placeholder="Nachricht"
              type="textarea"
              required
              name="message"
              id="message"
              value={this.state.message}
              onChange={e => this.onChangeHandler("message", e)}
            />
          </div>
          <div className="col-md-12 nopt nopb">
            <div className="row flex-align-center">
              <input
                name="checked"
                id="checked"
                style={{ width: "20px", cursor: "pointer" }}
                type="checkbox"
                onClick={() => this.setState({ checked: !this.state.checked })}
              />
              <label
                style={{ width: "auto", cursor: "pointer" }}
                htmlFor="checked"
              >
                Die{" "}
                <NavLink
                  target="_blank"
                  style={{ fontWeight: "bold", color: "#BFA482" }}
                  to="/datenschutz"
                >
                  Datenschutzhinweise
                </NavLink>{" "}
                habe ich zur Kenntnis genommen
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <button
              disabled={this.state.checked}
              className="btn-form"
              type="submit"
              value="Senden"
              onClick={e => this.handleFormSubmit(e)}
            >
              Senden
            </button>
            {this.state.mailSent && <p style={{color: 'green'}}>Ihre Nachricht wurde erfolgreich versendet</p>}
            {this.state.error && <p style={{color: 'red'}}>{this.state.error}</p>}
          </div>
        </div>
      </form>
    );
  }
}
