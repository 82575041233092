import React from "react";
import { useCookies } from "react-cookie";
import { NavLink } from "react-router-dom";
import { log } from "util";

function CookieNotification() {
  const [cookies, setCookie] = useCookies(["notification"]);

  function onClick(value) {
    setCookie("notification", value, { path: "/", maxAge: 1451520 });
  }

  if (cookies.notification !== "true") {
    return (
      <div className="cookieNotificationBar">
        <div className="row flex-align-center container nop">
          <div className="col-s-10">
            <p className="smallCookie">
              Diese Website verwendet Cookies, um Ihnen alle Funktionen bieten
              zu können. Weitergehende Informationen hierzu sind in unserer{" "}
              <NavLink exact to="/datenschutz">
                Datenschutzerklärung
              </NavLink>{" "}
              bei dem Unterpunkt Cookies zu finden.
            </p>
          </div>
          <div className="col-s-2">
            <button onClick={() => onClick(true)} className="btn cookieBtn">
              OK
            </button>
          </div>
        </div>
      </div>
    );
  } return null;
}

export default CookieNotification;
