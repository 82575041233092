import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Fade from "react-reveal/Fade";
import { ROOT_URL, SINGLETONS, GETKEYS } from "../../../../config";
import { fetch } from "../../../../redux/actions/index";
import Phone from "../../../../media/phone.png";
import Mail from "../../../../media/mail.png";
import Modalbox from "../../../reusable/modalbox";
import ContactForm from "../../../reusable/contactForm";

const Honorar = props => {
  const infoData = useSelector(state => state.info);
  const dispatch = useDispatch();
  const honorarData = useSelector(state => state.anwalt);

  useEffect(() => {
    if (infoData.empty) {
      dispatch(
        fetch("fetch_info", `${ROOT_URL + SINGLETONS}settings?token=${GETKEYS}`)
      );
    }
    if (honorarData.empty) {
      dispatch(
        fetch(
          "fetch_anwalt",
          `${ROOT_URL + SINGLETONS}homepage?token=${GETKEYS}`
        )
      );
    }
  });

  return (
    <div className="row honorar" style={{ marginBottom: "30px" }}>
      <div className="honorar-box">
        <div className="row">
          <div className="col-s-9 col-ml-12 col-lg-12">
            <h4
              style={{ maxWidth: "400px" }}
              className="middle primary-dark bold"
            >
              Um Frieden zu erzielen muss man stark handeln
            </h4>
            <p className="white" style={{ paddingTop: "15px" }}>
              Nikolaus Flechtner RA - Fachanwalt für Steuerrecht
            </p>
          </div>
          <div
            className="col-s-3 col-md-12"
            style={
              window.innerWidth < 768 ? { borderLeft: "2px solid white" } : null
            }
          >
            {window.innerWidth > 768 ? (
              <div
                className="flexbox flex-align-center"
                style={{ zIndex: "99999" }}
              >
                <p style={{ color: "white", marginRight: "15px" }}>
                  T {infoData.tel}
                </p>
                <Modalbox
                  btnStyle="btn-white-beige"
                  title="JETZT kontaktieren"
                  content={<ContactForm />}
                />
              </div>
            ) : (
              <>
                <div
                  className="row justify-center flex-align-center"
                  style={{ height: "100%" }}
                >
                  <a href={"tel:" + infoData.tel}>
                    <img
                      style={{ width: "50px", padding: "8px" }}
                      src={Phone}
                      alt="img"
                    />
                  </a>
                  <a href={"mailto:" + infoData.email}>
                    <img
                      style={{ width: "50px", padding: "8px" }}
                      src={Mail}
                      alt="img"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="container honorarContainer removeColOnSmallDevice"></div>

      <div className="container">
        <div className="row">
          <div className="col-ml-10 honorartext">
            <Fade top>
              <p className="primary ">
                <span className="bold fontSecondary">
                  Recht ist eine trockene und abstrakte Angelegenheit.
                </span>
                <br />
                In den Köpfen vieler Menschen scheint es so.
                <br />
                Doch schaut man einmal genauer, wird es deutlich:
              </p>
            </Fade>
            <Fade left>
              <h1 className="bold large primary pt-half">
                {honorarData.bigTitle}
              </h1>
            </Fade>
          </div>
        </div>

        <Fade bottom>
          <div className="row">
            <div className="col-ml-4 honorartext">
              <div
                className="primary-dark pt-half "
                dangerouslySetInnerHTML={{ __html: honorarData.descr1 }}
              ></div>
            </div>
            <div className="col-ml-4 honorartext">
              <div
                className="primary-dark pt-half"
                dangerouslySetInnerHTML={{ __html: honorarData.descr2 }}
              ></div>
            </div>
            <div className="col-ml-4 honorartext">
              <div
                className="primary-dark pt-half"
                dangerouslySetInnerHTML={{ __html: honorarData.descr3 }}
              ></div>
            </div>
          </div>
        </Fade>
        <hr className="beschissene-scheiße removeColOnSmallDevice" />
        <hr className="beschissene-scheiße2 removeColOnSmallDevice" />
      </div>

      <div className="recht pt-half" id="honorar">
        <div className="container">
          <div className="row">
            <div className="col-s-12 honorartext-white">
              <Fade top>
                <h1 className="large" style={{ fontWeight: "bold" }}>
                  {honorarData.dividerTitle}
                </h1>
              </Fade>
              <Fade bottom>
                <div
                  className="pt-half"
                  dangerouslySetInnerHTML={{ __html: honorarData.dividerText }}
                ></div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="container primary">
        <Fade bottom>
          <div className="row pt-half">
            <div
              className="col-ml-4 honorartext br nopt removeBorderOnMobile"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <div
                style={{ paddingBottom: "15px" }}
                dangerouslySetInnerHTML={{ __html: honorarData.descr11 }}
              ></div>
              <hr
                className="smallhr"
                style={{ position: "absolute", bottom: "0" }}
              />
            </div>
            <div
              className="col-ml-4 honorartext br nopt removeBorderOnMobile"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <div
                style={{ paddingBottom: "15px" }}
                dangerouslySetInnerHTML={{ __html: honorarData.descr22 }}
              ></div>
              <hr
                className="smallhr"
                style={{ position: "absolute", bottom: "0" }}
              />
            </div>
            <div
              className="col-ml-4 honorartext nopt"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <div
                style={{ paddingBottom: "15px" }}
                dangerouslySetInnerHTML={{ __html: honorarData.descr33 }}
              ></div>
              <hr
                className="smallhr"
                style={{ position: "absolute", bottom: "0" }}
              />
            </div>
          </div>
        </Fade>
      </div>
      <div className="bottomborder"></div>
    </div>
  );
};

export default Honorar;
