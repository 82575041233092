import React, { Component } from "react";

import { Helmet } from "react-helmet";

import Header from "./header";
import Rechtsgebiete from "./rechtsgebiete";
import Anwalt from "./anwalt";
import Honorar from "./honorar";
import Kontakt from "./kontakt";

import { throttle } from "../../reusable/helper/functional/_throttle";

class Home extends Component {
  constructor() {
    super();
    this.throttledScrollEvent = throttle(this.handleScrollEvent, 100);
  }

  state = {
    backgroundColor: "#1A5F38",
    rechtsgebiete: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.throttledScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScrollEvent);
  }

  checkElementPosition = elem => {
    var distance = 0;
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
    return (distance = distance < 0 ? 0 : distance);
  };

  handleScrollEvent = () => {
    let windowElementOffsetTop = window.pageYOffset;

    let Rechtsgebiet = document.getElementById("rechtsgebiete");
    let Anwalt = document.getElementById("anwalt");

    if (windowElementOffsetTop > this.checkElementPosition(Anwalt) - 300) {
      if (this.state.backgroundColor !== "#0F3923") {
        this.setState({ backgroundColor: "#0F3923" });
      }
      return;
    }
    if (
      windowElementOffsetTop >
      this.checkElementPosition(Rechtsgebiet) - 300
    ) {
      if (this.state.backgroundColor !== "#BFA482") {
        this.setState({ backgroundColor: "#BFA482", rechtsgebiete: true });
      }
      return;
    }
    if (windowElementOffsetTop > 0) {
      if (this.state.backgroundColor !== "#0F3923") {
        this.setState({ backgroundColor: "#0F3923" });
      }
      return;
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>
            {" "}
            präzise - persönlich - flexibel | Rechtsanwalt Flechtner | Home{" "}
          </title>
          <link rel="canonical" href={window.location.origin + "/"} />
        </Helmet>
        <Header bgColor={this.state.backgroundColor} />
        <Rechtsgebiete
          bgColor={this.state.backgroundColor}
          animate={this.state.rechtsgebiete}
        />
        <Anwalt bgColor={this.state.backgroundColor} />
        <Honorar />
        <Kontakt />
      </div>
    );
  }
}

export default Home;
