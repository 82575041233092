import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetch } from "../../../redux/actions/index";
import { ROOT_URL, SINGLETONS, GETKEYS } from "../../../config";
import { Helmet } from "react-helmet";
import Footer from '../../../router/footer';

const Datenschutz = props => {
  const datenschutzData = useSelector(state => state.datenschutz);
  const dispatch = useDispatch();

  useEffect(() => {
    if (datenschutzData.empty) {
      dispatch(
        fetch(
          "fetch_datenschutz",
          `${ROOT_URL + SINGLETONS}privacy?token=${GETKEYS}`
        )
      );
    }
  });

  return (
    <>
      <div
        className="container-big"
        style={{
          borderRight: "1px solid #1a5f38",
          borderLeft: "1px solid #1a5f38"
        }}
      >
        <Helmet>
          <title>
            präzise - persönlich - flexibel | Rechtsanwalt Flechtner |
            Datenschutzerklärung{" "}
          </title>
          <link
            rel="canonical"
            href={window.location.origin + "/datenschutz"}
          />
        </Helmet>
        <div className="container mainContainer">
          <h1 className="primary large break-word-medium">
            Datenschutzhinweise Rechtsanwalt Flechtner /{" "}
            <span className="sp-h1-small">
              https://www.rechtsanwalt-flechtner.de
            </span>
          </h1>
        </div>
        <div
          className="container datenschutz list"
          dangerouslySetInnerHTML={{ __html: datenschutzData.text }}
        ></div>
      </div>
      <Footer />
    </>
  );
};

export default Datenschutz;
