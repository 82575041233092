import { combineReducers } from 'redux';

import infoReducer from './info-reducer';
import serviceReducer from './service-reducer';
import anwaltReducer from "./anwalt-reducer";
import downloadReducer from "./download-reducer";
import datenschutzReducer from "./datenschutz-reducer";
import impressumReducer from "./impressum-reducer";
import aktuellesReducer from "./aktuelles-reducer";

const rootReducer = combineReducers({
  info: infoReducer,
  service: serviceReducer,
  anwalt: anwaltReducer,
  download: downloadReducer,
  datenschutz: datenschutzReducer,
  impressum: impressumReducer,
  aktuelles: aktuellesReducer
});

export default rootReducer;
