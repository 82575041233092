import React, { useEffect } from "react";

const Service = props => {
  const { title, text, repeat } = props.data;

  useEffect(() => {
      window.scroll({top: '0'});
  })

  function renderServiceParts() {
    return repeat.map(data => {
      return <ServiceParts data={data} />;
    });
  }

  return (
    <div className="container mainContainer">
      <div className="row">
        <div className="col-ms-12">
          <h1 className="large bold primary">Leistungen</h1>
          <p style={{paddingBottom: '60px'}} className="primary pt-half bold">{title}</p>
          <div style={{borderLeft: '1px solid rgba(0,0,0,0.2)', paddingLeft: '5px'}} dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </div>
      <div className="row">{renderServiceParts()}</div>
    </div>
  );
};

export default Service;

const ServiceParts = props => {
  const { title, text } = props.data.value;
  return (
    <div className="col-md-4" style={{ marginTop: "30px" }}>
      <hr className="smallhr" />
      {title ? (
        <p style={{ paddingTop: "15px" }} className="large bold ">
          {title}
        </p>
      ) : null}
      <div
        className="list"
        style={{ paddingTop: "15px" }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
};
