import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { ROOT_URL, COLLECTIONS, GETKEY } from "../../../../config";

import { fetch } from "../../../../redux/actions/";
import Fade from "react-reveal/Fade";

class Rechtsgebiete extends Component {
  componentDidMount() {
    if (this.props.loaded.empty) {
      this.props.fetch(
        "fetch_service",
        `${ROOT_URL + COLLECTIONS}service?token=${GETKEY}`
      );
    }
  }

  renderHelper = () => {
    if (this.props.loaded.empty) {
      return (
        <div>
          <p>...loading</p>
        </div>
      );
    } else {
      return this.props.service.map((data, i) => {
        return (
          <div className="col-lg-3 columnBorder mobilePaddingBottom">
            <Fade bottom delay={400}>
              <p className="bold">{data.title}</p>
              <div
                style={{ paddingBottom: "60px", paddingTop: "15px" }}
                className="lowerP"
                dangerouslySetInnerHTML={
                  i === 0
                    ? { __html: this.props.mainServices.service0 }
                    : i === 1
                    ? { __html: this.props.mainServices.service1 }
                    : i === 2
                    ? { __html: this.props.mainServices.service2 }
                    : null
                }
              ></div>

              <div style={{ maxWidth: "100px" }} className="bottomDesktop">
                <NavLink to={`/leistungen/${data.slug}`}>
                  <p style={{ width: "auto" }} className="r-link">
                    mehr
                  </p>
                  <hr className="smallhr" />
                </NavLink>
              </div>
              {window.innerWidth > 767 ? "" : <hr className="fullhr" />}
            </Fade>
          </div>
        );
      });
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-2 col-ml-2"></div>
        <div
          className="col-lg-10 col-ml-10 rechtsgebiete"
          id="rechtsgebiete"
          style={{ backgroundColor: this.props.bgColor, transition: "600ms" }}
        >
          <div className="col-lg-3"></div>

          <div className="row">
            <div className="col-lg-12">
              <h1 id="leistungen" className="large primary bold">
                Leistungen
              </h1>
              <p className="pt-half bold primary">
                Professionelle Rechtsberatung und Rechtsvertretung
              </p>
            </div>

            <div className="col-ms-12 nop">
              <div className="row sp-weil-screendesign-nervig">
                {this.renderHelper()}
              </div>
            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.service,
    service: data.service,
    mainServices: data.anwalt
  };
};

export default connect(mapStateToProps, { fetch })(Rechtsgebiete);
